import React, { useEffect, useState } from "react"

import { useInView } from "react-intersection-observer"

import Head from "decentraland-gatsby/dist/components/Head/Head"
import {
  preloadAs,
  preloadType,
} from "decentraland-gatsby/dist/components/Head/Preload"
import Background from "decentraland-gatsby/dist/components/Hero/Background"
import Image from "decentraland-gatsby/dist/components/Hero/Image"
import useFormatMessage from "decentraland-gatsby/dist/hooks/useFormatMessage"
import TokenList from "decentraland-gatsby/dist/utils/dom/TokenList"
import random from "decentraland-gatsby/dist/utils/number/random"
import { Container } from "decentraland-ui/dist/components/Container/Container"
import { Hero } from "decentraland-ui/dist/components/Hero/Hero"
import { Desktop } from "decentraland-ui/dist/components/Media/Media"
import { motion } from "framer-motion"

import JoinDiscord from "../components/Discord/JoinDiscord"
import Layout from "../components/Layout/Layout"
import PlayMenu from "../components/Layout/PlayMenu"
import forward from "../images/forward.svg"
import gradient_left from "../images/gradient-left.svg"
import gradient_right from "../images/gradient-right.svg"

import "./index.css"

const create_background =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/4xsNVeFFDFVd2Nx6sy8nSm/39ca4b224a3645f75f9d8bc065335c99/background_create.jpg"
const explore_background =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/5p5UK7cIYOqcwZcIJb4yck/999c00ab33931e79606c6271482cd1cb/background-explore.jpg"
const trade_background =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/5rerB5rk6gWb6gwHD1CQyl/d13c418b08242301145fca7167e0cf6a/background-trade.jpg"

const create_coin =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/2ca54tcf1p47dZfuMHHWHf/5efeec26fdf447646d303339c97ddfda/background-coin.png"
const create_main_coin =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/3VeCBwrx7hf7SlWpUzrMpR/8b71e619232cc136b86c3b3e86b3c36f/main-coin.png"
const create_main_platform =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/54D5lwanFzms8nLjaGkYWa/583510cf892516f89f830d315b4a1473/main-platform.png"
const create_platform =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/4zJ489gV62wJpsvOsGv1hr/332020bb74f98eb6fb42e0646812b0bb/background-platform.png"
const explore_anne =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/5FKZT9MfISiWE2pFSJsiu/5c8ef6c80e937786c61800e7fd738057/anne.png"
const explore_anne_tag =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/1UHc1S13JpO7KD8TXwBQYx/1b2447f378f589eff14a55a89d354a2a/anne-tag.png"
const explore_martin =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/436lPaVg8ZPP1QqT2QgKIt/6b36de63a96cb522b2ff4607501c93ab/martin.png"
const explore_martin_tag =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/208LLTXfHYOPGSjIt7d1Na/74a39eb97257fe801cf56ad9d1fbf5f5/martin-tag.png"
const trade_goggles =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/1tM2YWh1Q1RxZ9kRwUMYfW/9060fb9e3f46d7dcd6a81d9d9dc7210d/goggles.png"
const trade_helmet =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/7jmtPvVNvPKOFxUzbAQ1t2/4094f0adaa5b719c2e1ed0f7959e3c34/helmet.png"
const trade_jacket =
  "https://cms-images.decentraland.org/ea2ybdmmn1kv/5cGdBV9dyV9VAp1YidahhU/6e69edb8dbe97ba5f469f248ec2aae80/jacket.png"

export enum Feed {
  Scenes,
  Events,
  Adventures,
  Multiplayer,
}

const ExploreAnneVariants = {
  hidden: { opacity: 0, x: "-75px" },
  visible: {
    opacity: 1,
    x: "0px",
    transition: { duration: 0.5, ease: "backIn" },
  },
}

const ExploreMartinVariants = {
  hidden: { opacity: 0, x: "-40px" },
  visible: {
    opacity: 1,
    x: "0px",
    transition: { duration: 0.5, delay: 0.3, ease: "backIn" },
  },
}

const ExploreAnneTagVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.3, delay: 0.4, ease: "backIn" },
  },
}

const ExploreMartinTagVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { duration: 0.3, delay: 0.7, ease: "backIn" },
  },
}

const TradeItem1Variants = {
  hidden: { opacity: 0, scale: 0.5, x: "100px" },
  visible: {
    opacity: 1,
    scale: 1,
    x: "0px",
    transition: { duration: 0.3, delay: 0, ease: "backIn" },
  },
}

const TradeItem2Variants = {
  hidden: { opacity: 0, scale: 0.5, x: "100px" },
  visible: {
    opacity: 1,
    scale: 1,
    x: "0px",
    transition: { duration: 0.3, delay: 0.1, ease: "backIn" },
  },
}

const TradeItem3Variants = {
  hidden: { opacity: 0, scale: 0.5, x: "100px" },
  visible: {
    opacity: 1,
    scale: 1,
    x: "0px",
    transition: { duration: 0.3, delay: 0.2, ease: "backIn" },
  },
}

enum Section {
  Explorer,
  Create,
  Trade,
}

const sections = [Section.Explorer, Section.Create, Section.Trade]

export default function DiscordPage(props: any) {
  const [exploreRef, exploreInView] = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const [tradeRef, tradeInView] = useInView({ threshold: 0, triggerOnce: true })
  const [section, setSection] = useState<Section | null>(null)
  const l = useFormatMessage()

  useEffect(() => {
    setSection(sections[random(0, sections.length)])
  }, [])

  return (
    <Layout
      {...props}
      isFullscreen
      hideFooter={true}
      rightMenu={<PlayMenu hideDiscord />}
      className={TokenList.join([props.className, "layout__navbar--fixed"])}
    >
      <Head
        title={l("page.join_discord.title") || ""}
        description={l("page.join_discord.description") || ""}
        image="https://decentraland.org/images/decentraland.png"
      >
        <link
          rel="preload"
          href={forward}
          as={preloadAs(forward) || "image"}
          type={preloadType(forward)}
        />
        <link
          rel="preload"
          href={gradient_left}
          as={preloadAs(gradient_left) || "image"}
          type={preloadType(gradient_left)}
        />
        <link
          rel="preload"
          href={gradient_right}
          as={preloadAs(gradient_right) || "image"}
          type={preloadType(gradient_right)}
        />
        <link
          rel="preload"
          href={explore_background}
          as={preloadAs(explore_background) || "image"}
          type={preloadType(explore_background)}
        />
        <link
          rel="preload"
          href={explore_martin}
          as={preloadAs(explore_martin) || "image"}
          type={preloadType(explore_martin)}
        />
        <link
          rel="preload"
          href={explore_martin_tag}
          as={preloadAs(explore_martin_tag) || "image"}
          type={preloadType(explore_martin_tag)}
        />
        <link
          rel="preload"
          href={explore_anne}
          as={preloadAs(explore_anne) || "image"}
          type={preloadType(explore_anne)}
        />
        <link
          rel="preload"
          href={explore_anne_tag}
          as={preloadAs(explore_anne_tag) || "image"}
          type={preloadType(explore_anne_tag)}
        />
        <link
          rel="preload"
          href={create_background}
          as={preloadAs(create_background) || "image"}
          type={preloadType(create_background)}
        />
        <link
          rel="preload"
          href={create_coin}
          as={preloadAs(create_coin) || "image"}
          type={preloadType(create_coin)}
        />
        <link
          rel="preload"
          href={create_platform}
          as={preloadAs(create_platform) || "image"}
          type={preloadType(create_platform)}
        />
        <link
          rel="preload"
          href={create_main_coin}
          as={preloadAs(create_main_coin) || "image"}
          type={preloadType(create_main_coin)}
        />
        <link
          rel="preload"
          href={create_main_platform}
          as={preloadAs(create_main_platform) || "image"}
          type={preloadType(create_main_platform)}
        />
        <link
          rel="preload"
          href={trade_background}
          as={preloadAs(trade_background) || "image"}
          type={preloadType(trade_background)}
        />
        <link
          rel="preload"
          href={trade_helmet}
          as={preloadAs(trade_helmet) || "image"}
          type={preloadType(trade_helmet)}
        />
        <link
          rel="preload"
          href={trade_jacket}
          as={preloadAs(trade_jacket) || "image"}
          type={preloadType(trade_jacket)}
        />
        <link
          rel="preload"
          href={trade_goggles}
          as={preloadAs(trade_goggles) || "image"}
          type={preloadType(trade_goggles)}
        />
      </Head>

      {/* EXPLORE */}
      {section === null && <Hero className="left secondary full"></Hero>}
      {section === Section.Explorer && (
        <Hero className="left secondary full">
          <Hero.Content>
            <Background src={explore_background} />
            <Background src={gradient_left} />
          </Hero.Content>
          <Container>
            <JoinDiscord />
            <div ref={exploreRef} />
            <Desktop>
              <Hero.Content>
                <Image
                  background
                  backgroundSize="contain"
                  src={explore_martin}
                  initial="hidden"
                  animate={exploreInView ? "visible" : undefined}
                  variants={ExploreMartinVariants}
                />

                <Image
                  background
                  backgroundSize="contain"
                  src={explore_anne}
                  initial="hidden"
                  animate={exploreInView ? "visible" : undefined}
                  variants={ExploreAnneVariants}
                />

                <motion.div
                  initial="hidden"
                  animate={exploreInView ? "visible" : undefined}
                  variants={ExploreMartinTagVariants}
                >
                  <Image
                    background
                    backgroundSize="contain"
                    src={explore_martin_tag}
                    animate={{ y: "-.5em" }}
                    transition={{
                      yoyo: Infinity,
                      duration: 0.9,
                      ease: "easeInOut",
                    }}
                  />
                </motion.div>

                <motion.div
                  initial="hidden"
                  animate={exploreInView ? "visible" : undefined}
                  variants={ExploreAnneTagVariants}
                >
                  <Image
                    background
                    backgroundSize="contain"
                    src={explore_anne_tag}
                    animate={{ y: "-.7em" }}
                    transition={{
                      yoyo: Infinity,
                      duration: 1,
                      ease: "easeInOut",
                    }}
                  />
                </motion.div>
              </Hero.Content>
            </Desktop>
          </Container>
        </Hero>
      )}

      {/* CREATE */}
      {section === Section.Create && (
        <Hero className="right secondary full">
          <Hero.Content>
            <Background src={create_background} />
            <Background src={gradient_right} />
          </Hero.Content>
          <Container>
            <JoinDiscord />
            <Desktop>
              <Hero.Content>
                <Image
                  background
                  src={create_platform}
                  initial={{ x: "-65px" }}
                  animate={{ y: "-.5em" }}
                  transition={{
                    yoyo: Infinity,
                    duration: 0.9,
                    ease: "easeInOut",
                  }}
                />
                <Image
                  background
                  src={create_main_platform}
                  initial={{ x: "-45px" }}
                  animate={{ y: "-.5em" }}
                  transition={{
                    yoyo: Infinity,
                    duration: 1,
                    ease: "easeInOut",
                  }}
                />
                <Image
                  background
                  src={create_coin}
                  initial={{ x: "-65px" }}
                  animate={{ y: "1em" }}
                  transition={{
                    yoyo: Infinity,
                    duration: 0.9,
                    ease: "easeInOut",
                  }}
                />
                <Image
                  background
                  src={create_main_coin}
                  initial={{ x: "-45px" }}
                  animate={{ y: "1.5em" }}
                  transition={{
                    yoyo: Infinity,
                    duration: 1,
                    ease: "easeInOut",
                  }}
                />
                {/* <Image background src={explore_avatars2} /> */}
              </Hero.Content>
            </Desktop>
          </Container>
        </Hero>
      )}

      {/* TRADE */}
      {section === Section.Trade && (
        <Hero className="left secondary full">
          <Hero.Content>
            <Background src={trade_background} />
            <Background src={gradient_left} />
          </Hero.Content>
          <Container>
            <JoinDiscord />
            <div ref={tradeRef} />
            <Desktop>
              <Hero.Content>
                <Image
                  background
                  src={trade_jacket}
                  initial="hidden"
                  animate={tradeInView ? "visible" : undefined}
                  variants={TradeItem1Variants}
                />
                <Image
                  background
                  src={trade_goggles}
                  initial="hidden"
                  animate={tradeInView ? "visible" : undefined}
                  variants={TradeItem2Variants}
                />
                <Image
                  background
                  src={trade_helmet}
                  initial="hidden"
                  animate={tradeInView ? "visible" : undefined}
                  variants={TradeItem3Variants}
                />
              </Hero.Content>
            </Desktop>
          </Container>
        </Hero>
      )}
    </Layout>
  )
}
